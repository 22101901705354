import React from "react"
import Helmet from "react-helmet"
import Layout from "../templates/Layout"
import Container from "../components/Container"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { withLoginStateContext } from "../contexes/LoginStateContext"

const Credits = () => (
  <Layout>
    <Container>
      <Helmet title="Credits" />
      <h1>About the material</h1>
      <p>
        The course was created by Keijo Heljanko using University of Helsinki{" "}
        <OutboundLink
          href="https://www.helsinki.fi/en/researchgroups/data-driven-education"
          target="_blank"
          rel="noopener noreferrer"
        >
          Agile Education Research -research group
        </OutboundLink>{" "}
        created course platform.
      </p>
      <h2>Material</h2>
      <p>
        The material was written by Keijo Heljanko. The material is licensed
        under{" "}
        <OutboundLink
          href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Creative Commons BY-NC-SA 4.0.
        </OutboundLink>{" "}
        You are allowed to distribute and modify the content as long as the
        identities of original authors are not removed. If you modify the
        content, you have to use the same license. Commercial use without a
        permit is forbidden.
      </p>
    </Container>
  </Layout>
)

export default withLoginStateContext(Credits)
